import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../components/floating-button/floating-button';
import Layout from '../../../components/layout';
import React from 'react';

const RegulatedDigitalCashPage = (props) => {
    const components = props.data.cms.regulatedDigitalCashPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default RegulatedDigitalCashPage;

export const query = graphql`
query ($locale: String!){
  cms {
    regulatedDigitalCashPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          headerSubtitle: subtitle
          primaryImage {
            url
          }
          mobileContentOffset
          contentOffset
          backgroundImageHeight
          backgroundImage {
            url
          }
        }
        ... on STRAPI_ComponentComponentsTheyTrustUs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          theyTrustUsScrollColor
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          mobileImages {
            url
          }
          linesVisible
          images {
            url
          }
        }
        ... on STRAPI_ComponentComponentsHorizontalScroll {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          horizontalScrollColor
          hasMobileGrayBackground
          HorizontalScrollCaseStudy {
            case_study {
              slug
              link
              logo {
                url
              }
            }
          }
          HorizontalScrollBenefits {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabHeight
          tabs {
            type
            title
            subtitle
            scrollColor
            link
            labelImage {
              url
            }
            label
            images {
              url
            }
            imagesMetadata
            imagesTailwindClasses
          }
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
        }
        ... on STRAPI_ComponentComponentsBenefits {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          benefitsScrollColor
          hasDarkBackground
          Benefit {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsOffsetImageWithList {
          __typename
          topPusherHeightMobile
          topPusherHeight
          title
          offsetImageSubtitle: subtitle
          mobileImageHeight
          imageType
          imageOffset
          imageHeight
          hasLightBackground
          image {
            url
          }
          ImageWithText {
            image {
              url
            }
            content
          }
        }
        ... on STRAPI_ComponentVerticalTabsVerticalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          VerticalTabTitle {
            title
            image {
              url
            }
          }
          VerticalTabContent {
            title
            titleImage {
              url
            }
            contentTexts
            contentImages {
              url
            }
          }
        }
        ... on STRAPI_ComponentUseCasesUseCasesSection {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          UseCasesColumns {
            title
            subtitle
            icon {
              url
            }
            backgroundImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsCardsWithDarkBackground {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          WhySolutionIsUniqueCard {
            title
            subtitle
            image {
              url
            }
          }
          cardWithDarkBackgroundScrollColor
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
        ... on STRAPI_ComponentComponentsSingleImage {
          __typename
          image {
            url
          }
          topPusherHeight
          topPusherHeightMobile
          singleImageContent
        }
      }
    }
  }
}

`;
